<mat-sidenav-container class="sidenav-container bgTransparent" [hasBackdrop]='null'>
    <hr>
    <mat-sidenav #drawer class="sidenav bgTransparent" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
        <mat-toolbar>
            <hr>
        </mat-toolbar>
        <hr>
        <mat-nav-list>
            <div class="espacio"></div>
            <ul>
                <li *ngFor="let elemento of menuItems">
                    <a mat-list-item routerLink={{elemento.ruta}} routerLinkActive="leftmenuActive" (click)="toggleMenu(drawer)">
                        <img src={{elemento.icono}} class="prof_ico" /> {{elemento.etiqueta}}
                    </a>
                </li>
            </ul>

        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar class="bgcolorDark">
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle();_mobile(false)" *ngIf="isHandset$ | async">
        <mat-icon [ngStyle]="{'color':'white'}" aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
        </mat-toolbar>
        <ng-content></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>