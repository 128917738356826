import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tokenName } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class OpenpayService {

  constructor( private httpClient: HttpClient) {}

  merchandIDSandbox = 'mkhlhyhzipnwjnikydcr';
  merchandIDProd = 'm3hnzpyqlorpf0n3biek';

  openPayTraeSuscriptores() {
    const headers = {
      headers: new HttpHeaders()
        .set('Authorization', 'Basic cGtfZGJjMmUzMTgzOTY3NDYxMjkzOWUxN2Y2ZjQ3MGYzMzQ6Og==')
        .set('Content-Type', 'application/json')
    };

    
    return this.httpClient.get(
      'https://api.openpay.mx/v1/' + this.merchandIDProd + '/customers',
      headers);
  }

  openPayCreaToken(cardNumer: string, holderName: string, expiration: string, cvv: string) {
    const expirationArr = expiration.split('/');
    const expirationYear = expirationArr[1];
    const expirationMonth = expirationArr[0];

    const raw = JSON.stringify({
      card_number: cardNumer,
      holder_name: holderName,
      expiration_year: expirationYear,
      expiration_month: expirationMonth,
      cvv2: cvv
    });

    const headers = {
      headers: new HttpHeaders()
        .set('Authorization', 'Basic cGtfZGJjMmUzMTgzOTY3NDYxMjkzOWUxN2Y2ZjQ3MGYzMzQ6Og==')
        .set('Content-Type', 'application/json')
    };
    return this.httpClient.post(
      'https://api.openpay.mx/v1/' + this.merchandIDProd + '/tokens',
      raw,
      headers);
  }


  // tslint:disable-next-line:max-line-length
  openPayCreaSuscripcion(mail: string, holderName: string, tipoPlan: string, tokenID: string) {

    const body = new HttpParams()
    		.set('userName', holderName)
    	.set('mail', mail)
    	.set('tipoPlan', tipoPlan)
    	.set('token', tokenID);

    const headers = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    };
    return this.httpClient.post(
      'https://gigahert.com.mx/gastroAdmin/suscripciones.php',
      body.toString(),
      headers);
  }

  openPayCrearCliente(_name: string, _email:string){
    const raw = JSON.stringify({
      name: _name,
      email: _email,
      requires_account: false
    });

    const headers = {
      headers: new HttpHeaders()
        .set('Authorization', 'Basic cGtfZGJjMmUzMTgzOTY3NDYxMjkzOWUxN2Y2ZjQ3MGYzMzQ6Og==')
        .set('Content-Type', 'application/json')
    };
    return this.httpClient.post(
      'https://api.openpay.mx/v1/' + this.merchandIDProd + '/customers',
      raw,
      headers);
  }

  openPayGetClientSubscript(clientID){
    const headers = {
      headers: new HttpHeaders()
        .set('Authorization', 'Basic cGtfZGJjMmUzMTgzOTY3NDYxMjkzOWUxN2Y2ZjQ3MGYzMzQ6Og==')
        .set('Content-Type', 'application/json')
    };
    return this.httpClient.get(
      'https://api.openpay.mx/v1/' + this.merchandIDProd + '/customers/' + clientID + '/subscriptions',
      headers);
  }

  openPayCancelSubscription(mail){
    const body = new HttpParams()
      .set('mail', mail);

    const headers = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    };
    return this.httpClient.post(
      'https://gigahert.com.mx/gastroAdmin/cancelSuscripciones.php',
      body.toString(),
      headers);
  }


}
