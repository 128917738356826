<!-- <div class="d-flex">
  <div id="carouselHome" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselHome" data-slide-to="0" class="active"></li>
      <li data-target="#carouselHome" data-slide-to="1"></li>
      <li data-target="#carouselHome" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">

      <div class="contenedor-fijo">

        <h3>Cocina en cualquier lugar en cualquier momento</h3>
        <p class="card-text">
          <button *ngIf="suscrito == 0" 
          type="button" class="btn text-orange rounded-pill" [routerLink]="['/clases-gratis']">COCINAR
            AHORA</button>

            <button *ngIf="suscrito != 0" 
          type="button" class="btn text-orange rounded-pill" [routerLink]="['/cursos-online']">COCINAR
            AHORA</button>
        </p>
        <a (click)="scroll(target)" class="text-white obtener-info btn-link">Obtener información</a>

      </div>

      <div class="carousel-item active">
        <img class="d-block w-100" src="assets/imgs/imgSlider1.png" alt="">
        <div class="carousel-content">
          <p>ACCESO ILIMITADO A CLASES DE COCINA ONLINE.</p>
        </div>
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="assets/imgs/imgSlider2.png" alt="">
        <div class="carousel-content">
          <p>ENCUENTRA UNA GRAN VARIEDAD DE VIDEOS DE COCINA EN LINEA.</p>
        </div>
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="assets/imgs/imgSlider3.png" alt="">
        <div class="carousel-content">
          <p>DESCUBRE VIDEOS CON EL PASO A PASO DESDE CUALQUIER DISPOSITIVO.</p>
        </div>
      </div>
    </div>

  </div> 




</div> -->


<!-- <div class="sticky-top bg-white hidden-spacer"> </div> -->
<!-- <section>
  <div class="d-flex flex-column bordeNaranja p-4">

    <div class="d-flex justify-content-center">
      <p class="text-center">
        Nos inspira poder transmitir que la cocina es pasión, comunicación <br /> y una forma de disfrutar mientras
        aprendemos.
      </p>
    </div>
  </div>
</section> -->


<div class="backgroundImage">
  <div class="text-center container">

    <div class="space"></div>

    <h3>Acceso ilimitado a clases de cocina online.</h3>
    <h4>Cocina en cualquier lugar en cualquier momento.</h4>

    <div class="card-deck">

      <div class="card">
        <div class="card-header" style="background-color: #919191;">
          <h5 class="card-title">BÁSICA</h5>
        </div>
        <div class="card-body">

          <p class="card-text text-line-1">$2.99<span> USD</span></p>
          <p class="card-text text-line-2">Precio por mes</p>
          <p class="card-text text-line-3">Clases Online y Recetarios <br>Digitales on Demand</p>

        </div>
        <div class="card-footer">
          <button *ngIf="userData" 
          type="button" class="btn rounded-pill .bg-white btn-comprar1 btn-block" (click)="openPayment('2.99', 'basica')">
            SUSCRIBIRME
          </button>

          <button *ngIf="!userData" 
          type="button" class="btn rounded-pill .bg-white btn-comprar1 btn-block" (click)="openFastRegister('2.99', 'basica')" >
            SUSCRIBIRME
          </button>
          <a href="#" class="">Cancela en cualquier comento</a>
        </div>
      </div>

      <div class="card">
        <div class="card-header" style="background-color: #f26c16;">
          <h5 class="card-title">PREMIUM</h5>
        </div>
        <div class="card-body">

          <p class="card-text text-line-orange-1">$4.99 <span>USD</span></p>
          <p class="card-text text-line-orange-2">Precio por mes</p>
          <p class="card-text text-line-3">Clases OnLine y Recetarios<br>Digitales On Demand + 2<br>clases en Vivo</p>

        </div>
        <div class="card-footer">
          <button *ngIf="userData" 
          type="button" class="btn rounded-pill .bg-white btn-comprar1 btn-block" (click)="openPayment('4.99', 'premium')">
            SUSCRIBIRME
          </button>

          <button *ngIf="!userData"
          type="button" class="btn rounded-pill .text-white btn-comprar2 btn-block" (click)="openFastRegister('4.99', 'premium')" >
            SUSCRIBIRME
          </button>
          <a href="#" class="">Cancela en cualquier comento</a>
        </div>
      </div>

      <div class="card">
        <div class="card-header" style="background-color: #d6aa11;">
          <h5 class="card-title">GOLDEN PASS</h5>
        </div>
        <div class="card-body">
          
          <p class="card-text text-line-1">$59 <span>USD</span></p>
          <p class="card-text text-line-2">Precio anual</p>
          <p class="card-text text-line-3">¡Te regalamos 2 meses <br>gratis de suscripción <br>y 4 clases en vivo!</p>
            
         

        </div>
        <div class="card-footer">
          <button *ngIf="userData" 
          type="button" class="btn rounded-pill .bg-white btn-comprar1 btn-block" (click)="openPayment('59', 'golden')">
            SUSCRIBIRME
          </button>

          <button *ngIf="!userData" 
          type="button" class="btn rounded-pill .bg-white btn-comprar1 btn-block" (click)="openFastRegister('59', 'golden')">
            SUSCRIBIRME
          </button>
          <a href="#" class="">Cancela en cualquier comento</a>
        </div>
      </div>
    </div>
  </div>
</div>



<section>

  <div id='videoContainer' class="videoContainer" *ngIf="showVideoContainer" >
    <button class="btn closeVideo" (click)="toogleVideo()" >
      <img src="/assets/imgs/closeModalButton.png" width="50vm" height="auto">
    </button>
    <iframe [src]="urlSafe" 
        width="{{widthFrame}}"
        height="{{heightFrame}}" 
        frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
  </div>

  <div class="d-flex flex-column section2 ">

    <div class="d-flex p-5">

      <div class="flex-even p-2">
        <p class="section2-title">CLASES <span>GRATIS</span></p>
        <p class="card-text text-section2">
          Descubre una variedad de cursos de cocina y recetarios digitales. Los videos incluyen el paso a paso y una
          receta en PDF para descargar.
        </p>
        <!-- <a *ngIf="suscrito == 0"  class="link-section2" [routerLink]="['/suscripcion']">Suscribirme ahora</a> -->

        <button *ngIf="suscrito == 0" 
          type="button" class="btn text-orange rounded-pill" [routerLink]="['/suscripcion']">Suscribirme ahora</button>
      </div>

      <div class="flex-even p-2">
        <!-- <img class="img-fluid" src="assets/imgs/section2home1.png" width="75%" height="auto"> -->

        <div class="carrusel">
          
            <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item" *ngFor="let item of videoData;let index = index;let isFirst = first" (click)="toogleVideo(); showVideo(item.url_video)"
                  [ngClass]="{active:isFirst}">
                  
                  <div class="card d-block" [ngStyle]="{'background-image': 'url(' + item.imagen_video + ')'}">
                    <div class="card-body">
                      <div class="btn-play">
                        <img src="/assets/imgs/play-button.png" width="50vm" height="auto">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
        </div>
      </div>

    </div>

    <div class="d-flex">
      <div class="flex-even">
        <img class="img-fluid" src="assets/imgs/section2home2.png" width="75%" height="auto">
      </div>


      <div class="flex-even">
        <h3 class="section2-title">DISFRUTA DESDE <br><span>CUALQUIER DISPOSITIVO</span></h3>
        <p class="card-text text-section2">
          Disfruta de los videos desde cualquier dispositivo, incluyendo smartphone, smart TV y tablet.
        </p>
        <a class="link-section2" (click)="scroll(target)">Obtener información</a>
      </div>
    </div>
  </div>
</section>



<div class="d-flex" #target></div>
<app-accordion></app-accordion>
