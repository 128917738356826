<div class="modal-body" align="center" >
    <img src="assets/imgs/logoMiCuentaRegistro.png" width="20%" height="20%">
    <button type="button" class="close pull-right" aria-label="Close" (click)="onConfirm()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="titleModal">Recuperar contraseña</h3>
  
    <div class="d-flex flex-column">
  
      <form>
  
        <div class="d-flex justify-content-center box">
          <div class="form-group row">
            <label for="phoneNumber">Correo electrónico:</label>
            <input type="email" class="form-control" id="email" [(ngModel)]="txtEmail" (ngModelChange)="saverange($event)" name="txtEmail"  >
          </div>
        </div>
        
        <div class="d-flex justify-content-center">
          <p>Te enviaremos un mail con tu información</p>
        </div>
  
        <div class="d-flex justify-content-center">
          <button class="btn btnsOrange rounded-pill" (click)="recuperaPassword()">enviar</button>
        </div>
  
      </form>
    </div>
  </div>
