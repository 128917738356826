
<div id="bars" class="d-flex flex-row text-white  text-center">
  <div class="port-item p-4 " data-toggle="collapse" data-target="#home">
    <!-- <i class="fa fa-home d-block"></i> Home -->
    <img src="assets/imgs/logoFooter.png" width="40%" height="auto">
  </div>
  <div class="port-item p-4 " data-toggle="collapse" data-target="#resume">
    
    <p class="txtOrange">CONTACTO</p>

    <p class="text-white">
      <img src="/assets/imgs/iconoTelefono.png"> 81 8356 9212<br>
      <img src="/assets/imgs/iconoTelefono.png"> 81 8356 9218
    </p>
    <p class="text-white">
      <img src="/assets/imgs/iconoWhatsapp.png"> (81) 3254 0246
    </p>

    <p class="text-white">
      <img src="/assets/imgs/iconoCorreo.png"> clientes@gastrobureau.com
    </p>
  </div>
  <div class="port-item p-4 " data-toggle="collapse" data-target="#work">
    <!-- <i class="fa fa-folder-open d-block"></i> Work -->
    <p class="txtOrange"><a routerLink="/nosotros">NOSOTROS</a></p>

    <p class="txtOrange">FACTURACIÓN</p>
  </div>
  <div class="port-item p-4 " data-toggle="collapse" data-target="#contact">
    <!-- <i class="fa fa-envelope d-block"></i> Contact -->
    <div class="d-flex justify-content-center">
        <p class="txtOrange">Siguenos en</p>
      </div>

      <div class="d-flex justify-content-center">
        <a target="_blank" href="https://www.facebook.com/gastrobureau/">
          <img src="/assets/imgs/iconoFacebook.png" width="20vw" height="20vh">
        </a>
        
        <a target="_blank" href="https://www.instagram.com/gastrobureau/?hl=es-la">
          <img src="/assets/imgs/iconoInstagram.png" width="20vw" height="20vh">
        </a>
        
        <a target="_blank" href="https://twitter.com/GastroBureau">
          <img src="/assets/imgs/iconoTwitter.png" width="20vw" height="20vh">
        </a>
        
        
        
      </div>
  </div>
</div>


<div class="row avisoPrivacidad" id="footerInf" align="center">
  <div class="col">
    <a (click)= "openAvisoPRivacidad('https://beta.gastrobureau.tv/assets/pdfs/avisoPrivacidad.pdf')" >Aviso de privacidad</a> 
    <a (click)= "openAvisoPRivacidad('https://beta.gastrobureau.tv/assets/pdfs/terminosCondiciones.pdf')"> Términos y condiciones</a>
  </div>
</div>
<ngx-spinner></ngx-spinner>
