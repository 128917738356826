<div class="carrusel" *ngIf="videoData" >
    <div class="row">
        <div class="col-1"></div>
        <div class="col">
            <h3>{{tituloVideos}}</h3>
        </div>
        <div class="col-1"></div>
    </div>

    <div class="row">
        <div class="col-1" >
            <a class="carousel-control-prev" href="#{{carouselID}}" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
        </div>
        <div class="col">
            <div id="{{carouselID}}" class="carousel slide" >
                <div class="carousel-inner">
                  <div class="carousel-item " *ngFor="let item of videoData;let index = index;let isFirst = first" [ngClass]="{active:isFirst}">
              
                    <div class="row">
                      <div class="col-sm" *ngFor="let cardVideo of item; let idx = index">
                        <div class="card card0 d-block w-100" 
                        style="cursor: pointer;"
                        [ngStyle]="{'background-image': 'url(' + cardVideo.imagen_video + ')'}">

                        <button class="btn favOff" (click)="saveFavorite(cardVideo.id_video)" *ngIf = "tituloVideos != 'MIS FAVORITOS' && tituloVideos != 'AGREGADOS RECIENTEMENTE' " >
                          <img src="/assets/imgs/imgFavOff.png" width="30vw" height="30vh" *ngIf="!cardVideo.isFavorite" >
                          <img src="/assets/imgs/imgFavOn.png" width="30vw" height="30vh" *ngIf="cardVideo.isFavorite" >
                        </button>

                          <div class="card-body" >
                            <div class="containerBody" 
                            [routerLink]="['/product-details',cardVideo.id_video]" ></div>
                            <p class="card-text">
                              {{cardVideo.titulo_video}}
                            </p>
                            <!-- <div class="lblNuevo"><span>NUEVO</span></div> -->
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
        </div>
        <div class="col-1">
            <a class="carousel-control-next" href="#{{carouselID}}" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
        </div>
    </div>
</div>
