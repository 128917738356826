<div class="modal-header">
    <!-- <h4 class="modal-title">Hi there!</h4> -->
    <button type="button" class="close" aria-label="Close" (click)="onConfirm()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" align="center">
    <img src="assets/imgs/logoMiCuentaRegistro.png" width="150px" height="58px">
    <h3 class="titleModal">Confirma tu modo de pago</h3>

    <div class="d-flex flex-column btnsPago">
        <button type="button" class="btn rounded-pill" (click)="paymentTarjeta()" >TARJETA DE CRÉDITO/DÉBITO</button>

        <!-- <button type="button" class="btn rounded-pill" (click)="paymentPaypal()" >PAYPAL</button> -->
        <!-- <ngx-paypal [config]="payPalConfig"></ngx-paypal> -->
        

        <!-- <button type="button" class="btn rounded-pill" (click)="paymentEfectivo()" >EFECTIVO EN TIENDAS DE CONVENIENCIA</button> -->

        <!-- <button type="button" class="btn rounded-pill" (click)="paymentOxxo()" >EFECTIVO EN OXXO</button> -->

        <div #paypal></div> 
    </div>
    
    
  </div>
  