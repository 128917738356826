<div id="spaceNavBar"></div>

<div id="headerStore" class="row justify-content-center align-items-center">
  <div class="col-12">
    <!-- <h3 style="text-align: center;">BIENVENIDO A NUESTRA <br>TIENDA EN LINEA</h3> -->
    <h3 style="text-align: center;">Estamos cocinando una sorpresa para ti. <br>¡Esperalo 2021!</h3>
  </div>
</div>


<!-- <div class="row" style="padding-top: 40px;">
  <div class="col-sm-2">
    <div style="height: 300px;" align="right">
      <p>Categorías</p>
      <p>Productos</p>
    </div>
  </div>


  <div class="col ">

    <div class="grid"
      data-masonry='{ "itemSelector": ".grid-item",  "gutter": 20 , "horizontalOrder": true, "columnWidth": 190}'>
      <div class="grid-item" *ngFor="let item of [1,2,3,4,5,6,7,8]" align="center">
        <a [routerLink]="['/product-details','23456']">
          <div class="">
            <div class="">
              <span>lorem ipsum</span>
            </div>

            <div class="" style="border: 1px solid rgb(168, 168, 168); padding-top: 30px; padding-bottom: 20px;">
              <img src="/assets/imgs/imgProducto1.png" style="max-width:70%; max-height:auto;">
            </div>

            <div class="">
              <p>lorem ipsum</p>
              <p>MX$400.00</p>
            </div>
          </div>
        </a>

      </div>

    </div>
  </div>

</div> -->
