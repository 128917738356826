<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="onConfirm()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" align="center">
  
    <div class="d-flex justify-content-center box descripcion">
        <img src="/assets/imgs/chefEdna.png" width="50%" height="50%" *ngIf="idNosotros === 1" >
        <img src="/assets/imgs/chefAlejandro.png" width="50%" height="50%" *ngIf="idNosotros === 2" >
        <img src="/assets/imgs/chefSergio.png" width="50%" height="50%" *ngIf="idNosotros === 3" >

        <div >
            <h3 style="color: #eb7024;">{{nombreNosotros}}</h3>
            <p style="color: #4c4a59; text-align: left; margin-left: 10px;">
              {{textoNosotros}}
            </p>
        </div>
    </div>


</div>
