<div class="modal-header">
  <!-- <h4 class="modal-title">Hi there!</h4> -->
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" align="center">
  <img src="assets/imgs/logoMiCuentaRegistro.png" width="150px" height="58px">
  <h3 class="titleModal">REGISTRO</h3>
  <form [formGroup] = 'registerForm'>
    <div class="form-group">
      <div class="row">
        <div class="col">
          <span class="has-float-label">
            <input type="text" class="form-control" id="txtName" formControlName="username">
            <label >Nombre <span>*</span> </label>
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <span class="has-float-label">
            <input type="email" class="form-control" id="txtEmail" formControlName="email">
            <label >E-mail <span>*</span></label>
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <span class="has-float-label">
            <input type="password" class="form-control" id="txtPassword" formControlName="password">
            <label>Contraseña <span>*</span></label>
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <span class="has-float-label">
            <input type="password" class="form-control" id="txtPasswordConfirm" formControlName="password2">
            <label>Confirmar contraseña <span>*</span></label>
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col">
            <button type="button" class="btn btn-primary form-control" 
                  (click)="doRegister()" 
                  [disabled]="!registerForm.valid">
                    Crear cuenta
            </button>
        </div>
      </div>

    </div>


    
  </form>
  <div class="row">
    <div class="col">
      <button type="button" class="btn btn-link" (click)="activeModal.close()" *ngIf="isFromLogin" >¿Ya tienes una cuenta? Entra aquí</button>
      <button type="button" class="btn btn-link" (click)="openLoginModal()" *ngIf="!isFromLogin" >¿Ya tienes una cuenta? Entra aquí</button>
    </div>
  </div>
</div>
