<div id="sectionSuperior" align="center" class="row d-flex align-items-center justify-content-center">

  <div id='videoContainer' class="videoContainer" *ngIf="showVideoContainer" >
    <button class="btn closeVideo" (click)="toogleVideo()" >cerrar</button>
    <iframe [src]="urlSafe" 
        width="{{widthFrame}}"
        height="{{heightFrame}}" 
        frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
  </div>

  <div class="col">
    <h3 style="padding: 30px;line-height: 1.2em;">CLASES GRATIS</h3>

    <!-- <p style="padding: 30px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praese</p> -->

    <div class="carrusel">
      <div class="row">
        <div class="col-1">
          <a class="carousel-control-prev" href="#carouselCursos" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
        </div>
        <div class="col">
          <div id="carouselCursos" class="carousel slide">
            <div class="carousel-inner">
              <div class="carousel-item " *ngFor="let item of videoData;let index = index;let isFirst = first"
                [ngClass]="{active:isFirst}">

                <div class="row">
                  <div class="col-sm" *ngFor="let card of item" (click)="toogleVideo(); showVideo(card.url_video)">
                    <div class="card d-block w-100"
                    [ngStyle]="{'background-image': 'url(' + card.imagen_video + ')'}">
                      <div class="card-body"></div>
                    </div>
                    <div class="row" align="center" style="color: white;">
                      <div class="col">{{card.titulo_video}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-1">
          <a class="carousel-control-next" href="#carouselCursos" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>


    <button
    type="button" class="btn rounded-pill text-white" (click)="openSuscripcion()" >OBTENER SUSCRIPCION</button>
    
  </div>

  


</div>
