<div class="modalPago">
  <div class="modal-header">
    <!-- <h4 class="modal-title">Hi there!</h4> -->
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" align="center">
    <img src="assets/imgs/logoMiCuentaRegistro.png" width="150px" height="58px">


    <div class="row divider">
      <p>MÉTODO DE PAGO</p>
    </div>

    <div class="row">


        <form [formGroup]='paymentForm' align="left">
            <div id="tarjetaCredito" class="row">
              <button type="button" class="btn radioButtonOn"></button>
              <p>TARJETA DE CRÉDITO/DÉBITO</p>
            </div>
      
            <div class="row divider rowForm">
              <div class="form-group col col-form">
                <label for="">Nombre en la tarjeta</label>
                <input type="text" class="form-control" id="txtNombre" placeholder="Jonh Doe">
              </div>
              <div class="form-group col col-form">
                <label for="">No de tarjeta</label>
                <input type="text" class="form-control" id="txtNumero" placeholder="1234 1234 1234 1234">
              </div>
              <div class="form-group col col-form">
                <label for="">Fecha de vencimiento</label>
                <div class="row" style="padding: 0;">
                  <div class="col-sm-4 pr-1">
                    <input type="text" class="form-control" id="txtMesVence" placeholder="dd">
                  </div>
                  <div class="col-sm-8 pl-1">
                    <input type="text" class="form-control" id="txtYearVence" placeholder="yyyy">
                  </div>
                </div>
              </div>
            </div>
            <br>
          </form>

    </div>
    

    <div class="row divider rowPaypal">
      
        <button type="button" class="btn radioButtonOff"></button> 
        <img src="/assets/imgs/paypalLogo.png" width="10%" height="10%" >
      
    </div>
  </div>

  <div class="row rowButton" >
      <div class="col" align = "right">
        <button type="button" class="btn rounded-pill text-white">AGREGAR</button>
      </div>
    
  </div>

</div>
