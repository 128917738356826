<div id="sectionSuperior">
  <div class="textoTitulo">
    <p>CURSOS<br>PRESENCIALES</p>
  </div>

  <div class="btnMasInformacion">
    <button type="button" class="btn btn-link">
      Obtén más información
    </button>
  </div>
</div>

<!-- 
<div class="carrusel">
  <div class="row" align="center">
    <div class="col">
      <h3>NUESTROS CURSOS</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-1">
      <a class="carousel-control-prev" href="#carouselCursos" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
    </div>
    <div class="col">
      <div id="carouselCursos" class="carousel slide">
        <div class="carousel-inner">
          <div class="carousel-item " *ngFor="let item of [1,2,3];let index = index;let isFirst = first"
            [ngClass]="{active:isFirst}">

            <div class="row">
              <div class="col-sm" *ngFor="let card of [1,2,3, 4]">
                <div class="card d-block w-100">
                  <div class="card-body"></div>
                </div>
                <div class="row" align="center" style="color: white;">
                  <div class="col">Curso 1</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-1">
      <a class="carousel-control-next" href="#carouselCursos" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>

  <div class="row" align="center">
    <div class="col">
      <button type="button" class="btn btn-orange rounded-pill" (click)="openModalCongrat()">Comprar</button>
    </div>
  </div>
</div> -->



<!-- <div id="sectionInferior">
  <div class="row" style="color: white;" align="center">
    <div class="col">
      <h2>SOLICITA INFORMACIÓN</h2>
    </div>
  </div>

  <div class="row">


    <div class="col-md-10 mx-auto">
      <form>
        <div class="form-group row">
          <div class="col-sm-6">
            <label for="nombre">Nombre</label>
            <input type="text" class="form-control" id="nombre" placeholder="Nombre">
          </div>
          <div class="col-sm-6">
            <label for="email">Correo</label>
            <input type="email" class="form-control" id="email" placeholder="Correo">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-6">
            <label for="whatsapp">Whatsapp</label>
            <input type="tel" class="form-control" id="whatsapp" placeholder="Whatsapp">
          </div>
          <div class="col-sm-6">
            <label for="cursoInteres">Curso en el que estás interesado</label>
            <input type="text" class="form-control" id="cursoInteres" placeholder="Curso">
          </div>
        </div>

        <div class="form-group row" align="center">
          <div class="col">
            <button type="button" class="btn px-4 rounded-pill text-orange bg-white" (click)="showInformation()">
              Solicitar información
            </button>
          </div>

        </div>

      </form>
    </div>
  </div>
</div> -->


