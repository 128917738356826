<div class="modal-header">
  <!-- <h4 class="modal-title">Hi there!</h4> -->
  <button type="button" class="close" aria-label="Close" (click)="onCloseModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" align="center">
  <img src="assets/imgs/logoMiCuentaRegistro.png" width="150px" height="58px">
  <h3 class="titleModal">Crea tu cuenta para que comiences tu membresía</h3>
  <form [formGroup] = 'registerForm'>
    <div class="form-group">

      <div class="d-flex flex-column">
        <div class="d-flex justify-content-around">

          <span class="has-float-label">
            <input type="text" class="form-control" id="username" formControlName="username">
            <label>Nombre <span>*</span></label>
          </span>

          <span class="has-float-label">
            <input type="password" class="form-control" id="password" formControlName="password">
            <label>Contraseña <span>*</span></label>
          </span>

        </div>

      </div>

      <div class="d-flex flex-column singleFormField">
        
        <span class="has-float-label">
            <input type="email" class="form-control" id="email" formControlName="email">
            <label>E-mail <span>*</span></label>
        </span>
        
      </div>

      <div class="d-flex flex-column">
        
          <button type="button" class="btn btn-primary form-control" (click)="onConfirm()">Crear cuenta</button>
        
      </div>
    </div>
  </form>
</div>
