<div class="modal-body p-4" align="left">
    <button type="button" class="close" aria-label="Close" (click)="onConfirm()">
      <span aria-hidden="true">&times;</span>
    </button>
    <img src="assets/imgs/logoMiCuentaRegistro.png" width="20%" height="20%">
    
    <p class="p-3">
        Debido a la contingencia ocasionada por el <span>COVID-19</span>, todas nuestras clases presenciales se encuentran suspendidas. 
        <br><br>
        Si adquiriste alguna clase que no pudiste tomar, por favor contáctanos por WhatsApp <span>8132540246</span>
    </p>
  </div>
