<div class="bgcolorDark text-justify">
    <div class="row">
        <div class="col-sm-12 col-md-8 col-lg-8">
            <h1 class="text-orange title">MI PLAN</h1>
            <h1><small class="text-white">Información sobre tu plan</small></h1>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 col-md-6 col-lg-8" *ngIf="tipoSuscripcion != 0" >
            <h2 id="tipoplan" class="text-orange ">Plan {{nombreSuscripcion}}</h2>
            <p id="detalle" class="texto">${{precioSuscripcion}}USD, {{descripcionSuscripcion}} </p>
        </div>
    </div>
    <br>
    <!-- <div class="row">
        <div class="col-sm-4 col-md-6 col-lg-8">
            <small><a class="text-orange" href="">Cancela en cualquier momento</a></small>
        </div>
    </div> -->
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-4">
            
            <input type="button" class="btn btn-orange" value="SUBIR TU SUSCRIPCIÓN" (click)="openSuscripciones()" *ngIf="tipoSuscripcion != 0" >

            <input type="button" class="btn btn-orange" value="SUSCRIBIRSE" (click)="openSuscripciones()" *ngIf="tipoSuscripcion == 0" >
        </div>

        <div class="col-sm-12 col-md-1 col-lg-1 ml-2"><br></div>
        
        <div class="col-sm-12 col-md-4 col-lg-4" *ngIf="tipoSuscripcion == 2">
            <input type="button" class="btn btn-orange" value="CANCELAR SUSCRIPCIÓN" (click)="cancelaSuscripcion()">
        </div>

        <div class="col-sm-12 col-md-4 col-lg-4" *ngIf="tipoSuscripcion == 1" >
            <a class="btn btn-orange" href="https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_subscr-find&alias=EOnAFhvbk3zsr7j2hmgI-K4rMrYgkwdLYLwS7qxkeD8cobVrAxNH7poMBPumyyOHoHT01tu81qDBvmFT" target="_blank" (click)="cancelaSuscripcion()" >
                CANCELAR SUSCRIPCIÓN
            </a>
        </div>
    </div>

</div>