<div class="modal-header">
    <!-- <h4 class="modal-title">Hi there!</h4> -->
    <button type="button" class="close" aria-label="Close" (click)="onConfirm()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" align="left">
    <img src="assets/imgs/logoMiCuentaRegistro.png" width="20%" height="20%">
    <h3 class="titleModal">TARJETA EN TIENDAS DE CONVENIENCIA</h3>

    <div class="d-flex flex-column">

        <div class="d-flex justify-content-around box">
            <img src="/assets/imgs/7elevenLogo.png" width="10%" height="10%">
            <img src="/assets/imgs/fAhorroLogo.png" width="20%" height="20%">
            <img src="/assets/imgs/fBenavidesLogo.png" width="22%" height="22%">
            <img src="/assets/imgs/walmartLogo.png" width="22%" height="22%">
        </div>

        <div class="d-flex flex-column">
            <span class="tituloPasos">Pasos para tu pago por tienda</span>
        </div>

        <div class="d-flex justify-content-around box p-3" align="center">

            <div class="d-flex flex-column">
                <img src="/assets/imgs/pagoPaso1.png" width="25%" height="25%">
                <span class="textPasos">Haz clic en el botón “Realizar pedido”, así tu compra quedará en espera de que realices tu pago.</span>
            </div>

            <div class="d-flex flex-column">
                <img src="/assets/imgs/pagoPaso2.png" width="35%" height="35%">
                <span class="textPasos">Imprime tu recibo, llévalo a tu tienda de conveniencia más cercana y realiza el pago.</span>
            </div>

            <div class="d-flex flex-column">
                <img src="/assets/imgs/pagoPaso3.png" width="35%" height="35%">
                <span class="textPasos">Inmediatamente después de recibir tu pago te enviaremos un correo electrónico con la confirmación de pago.</span>
            </div>

        </div>
            
        <div class="d-flex justify-content-around box">
            <span class="spanText col-9">Tus datos personales se usarán para procesar tu pedido, ofrecerte soporte y atención sobre tu actividad en nuestro sitio y para los propósitos descritos en nuestro aviso de privacidad.</span>
            <button class="btn rounded-pill btnsOrange">Realizar pedido</button>
        </div>

    </div>
    
    
  </div>
  