<div id="section-video-day">
    <div id='videoContainer' class="videoContainer" *ngIf="showVideoContainer">
        <button class="btn closeVideo" (click)="toogleVideo()" >
            <img src="/assets/imgs/closeModalButton.png" width="50vm" height="auto">
        </button>
        <!-- <p style="color: aqua;">AQUI VA EL VIDEO</p> -->
        <iframe src="https://player.vimeo.com/video/391619826/" width="{{widthFrame}}" height="{{heightFrame}}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div class="textoNombrePlatillo">
        <p>TARTA DE TOMATE Y REQUESÓN</p>
    </div>

    <div class="btnPlayNow">
        <button type="button" class="btn rounded-pill" (click)="toogleVideo()">
            REPRODUCIR AHORA
        </button>
    </div>
</div>

<app-carrusel-videos carouselID='continuarViendo'></app-carrusel-videos>

<app-carrusel-videos carouselID='agregadosRecientemente'></app-carrusel-videos>

<app-carrusel-videos carouselID='misFavoritos'></app-carrusel-videos>

<app-carrusel-videos carouselID='todosLosVideos'></app-carrusel-videos>

<div class="d-flex flex-column recetas" style="background-color: #1d2126;">

    <h3>RECETARIOS DIGITALES</h3>
    <div class="row">
        <div class="col">
            <div class="card-columns" align="center">
        
                <div class="card" *ngFor="let receta of recetarios" >
                    <img src="{{receta.imagen_recetario}}" width="60%" height="auto" (click)="openPDF(receta.pdf_recetario)">
                </div>
            </div>
        </div>
    </div>
</div>