<div id="sectionSuperior" align="center">

  <div id="bars" class="d-flex justify-content-center box descripcion">

    <div class="port-item">
      <img src="/assets/imgs/chefsNosotros.png" width="80%" height="80%">
    </div>

    <div class="port-item">
      <p style="color: white;">
        Somos grandes maestros de la gastronomía que disfrutan al compartir su experiencia, pero lo que más nos une es
        que a pesar de ser grandes chefs primero somos unos apasionados de la cocina. Nos inspira poder transmitir que
        la cocina es pasión, comunicación y una forma de disfrutar mientras aprendemos.

        Ofrecemos diferentes servicios:
        - Clases de cocina presencial con diferentes chefs.
        - Videos y recetarios digitales.
        - Eventos de team building para empresas.
        - Eventos privados sociales y empresariales.
      </p>
    </div>
  </div>

</div>

<div class="d-flex flex-column chefsSection" align="center">

  <div class="p-2 titleChefs">
    <h3>Equipo/Chefs invitados</h3>
  </div>

  <div class="d-flex justify-content-around p-2 chefs">

    <div class="card" (click)="openModal(1)" style="width: 18rem;">
      <img src="/assets/imgs/chefEdna.png" width="100%" height="auto">
      <div class="card-body">
        <p>EDNA<br><span>ALANIS</span></p>
      </div>
    </div>



    <div class="card" (click)="openModal(2)" style="width: 18rem;">
      <img src="/assets/imgs/chefAlejandro.png" width="100%" height="auto">
      <div class="card-body">
        <p>ALEJANDRO<br><span>VELAZQUEZ OLMEDO</span></p>
      </div>

    </div>

    <div class="card" (click)="openModal(3)" style="width: 18rem;">
      <img src="/assets/imgs/chefSergio.png" width="100%" height="auto">
      <div class="card-body">
        <p>SERGIO<br><span>RÍOS</span></p>
      </div>
    </div>
  </div>


</div>
