<div class="d-flex flex-column  section3" align="center">
  <h3>PREGUNTAS FRECUENTES</h3>

  <div class="d-flex justify-content-center">
    <div id="accordion">
      <div class="card">
        <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
          aria-controls="collapseOne">
          <h5 class="mb-0">
            <button class="btn btn-link">
              ¿Qué es GastroBureau?
            </button>
          </h5>
        </div>

        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
          <div class="card-body">
            GastroBureau es la plataforma ideal para aprender a cocinar en línea desde tu hogar. Encuentra una variedad
            de
            cursos de cocina online que incluyen un video con el paso a paso y la receta en PDF descargable. <br>
            Además podrás encontrar recetarios digitales que incluyen diferentes opciones para todo momento.
          </div>
        </div>
      </div>


      <div class="card">
        <div class="card-header collapsed" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo"
          aria-expanded="false" aria-controls="collapseTwo">
          <h5 class="mb-0 ">
            <button class="btn btn-link">
              ¿Cuánto cuesta GastroBureau?
            </button>
          </h5>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
          <div class="card-body">
            Contamos con 3 tipos de suscripciones accesibles para todas las personas. Descubre planes desde $2.99 USD al mes.
          </div>
        </div>
      </div>


      <div class="card">
        <div class="card-header collapsed" id="headingThree" data-toggle="collapse" data-target="#collapseThree"
          aria-expanded="false" aria-controls="collapseThree">
          <h5 class="mb-0">
            <button class="btn btn-link">
              ¿Dónde puedo verlo?
            </button>
          </h5>
        </div>
        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
          <div class="card-body">
            Puedes acceder al contenido desde cualquier lugar y con cualquier dispositivo con conexión a Internet
            incluyendo tu smartphone, smart TV o tablet.
          </div>
        </div>
      </div>


      <div class="card">
        <div class="card-header collapsed" id="acordion4" data-toggle="collapse" data-target="#collapse4"
          aria-expanded="false" aria-controls="collapse4">
          <h5 class="mb-0">
            <button class="btn btn-link ">
              ¿Qué puedo encontrar aquí?
            </button>
          </h5>
        </div>
        <div id="collapse4" class="collapse" aria-labelledby="collapse4" data-parent="#accordion">
          <div class="card-body">
            GastroBureau cuenta con un amplio catálogo de cursos de cocina online para todo tipo de ocasiones, incluyen
            videos con el paso a paso y la receta con instrucciones en PDF para imprimir desde casa. Podrás encontrar
            recetarios digitales que incluyen diferentes opciones para todo momento y próximamente se lanzará la tienda
            en
            línea con una variedad de productos de consumo y para tu cocina.
          </div>
        </div>
      </div>


      <div class="card">
        <div class="card-header collapsed" id="acordion5" data-toggle="collapse" data-target="#collapse5"
          aria-expanded="false" aria-controls="collapse5">
          <h5 class="mb-0">
            <button class="btn btn-link ">
              ¿Cómo cancelo o cambio mi suscripción?
            </button>
          </h5>
        </div>
        <div id="collapse5" class="collapse" aria-labelledby="collapse5" data-parent="#accordion">
          <div class="card-body">
            La suscripción no incluye contratos forzosos, por lo que una vez suscrito podrás ingresar a tu perfil y seleccionar la opción de “Mi Plan” donde podrás cambiar tu suscripción o cancelar en cualquier momento.
          </div>
        </div>
      </div>


    </div>
  </div>


</div>
